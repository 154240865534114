




















































import Vue from "vue";
import content from "@/content.json";
import progressTracker from "@/components/lib/ProgressTracker.vue";
import BeheerTable from "@/components/lib/BeheerTable.vue";
import FindingsSummary from "@/components/FindingsSummary.vue";

export default Vue.extend({
  name: "DownloadComponent",
  components: { FindingsSummary, progressTracker, BeheerTable },
  data: () => ({
    page: content.pages.download,
    count_text: content.pages.aantal_bevindingen,
    error: "",
    loading: false,
    aanleverStatusData: [],
    dialog: {
      reupload: false,
    },
    trackerName: ""
  }),
  methods: {
    downloadFile(variant = "Klassiek") {
      this.loading = true;
      this.trackerName = `download-spenddata-${Date.now()}`
      this.$request({
        url: `${this.$store.state.APIurl}/document/download?document_id=${this.$store.state.user.document.id}&variant=${variant}&tracker_name=${this.trackerName}`,
        method: "POST",
        responseType: "blob",
      })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            this.$store.state.user.document.naam
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading = false;
        })
        .catch((error: any) => {
          this.$data.error = error.response.data.detail;
        });
    },
    downloadTemplate() {
      this.$request({
        url: `${this.$store.state.APIurl}/document/download-template`,
        method: "POST",
        responseType: "blob",
      })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "Template spenduitvraag.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading = false;
        })
        .catch((error: any) => {
          console.log(error);
          // this.$data.error = error
        });
    },
    closeProgressTracker() {
      this.loading = false;
      this.error = "";
    },
    fetchAanleverStatus() {
      this.$request(
        `${this.$store.state.APIurl}/document/aanlever-status`
      ).then((response: any) => {
        this.aanleverStatusData = response.data;
      });
    },
  },
  computed: {
    num_findings() {
      return this.$store.state.user.numFindings;
    },
  },
  mounted() {
    this.fetchAanleverStatus();
  },
});
